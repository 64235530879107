import React from "react"

import {
  ArrayInput,
  DateInput,
  SimpleFormIterator,
  TextInput,
  required,
  maxLength,
} from "react-admin"

import {
  isPossibleNumber,
  parsePhoneNumberWithError,
  ParseError,
} from "libphonenumber-js"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  inline: {
    display: "inline-flex",
    marginRight: "1rem",
  },
})

export const phoneValidate = (v) => {
  if (!v) {
    return "Phone number is required"
  } else {
    try {
      parsePhoneNumberWithError(v)
    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.
        return error.message
      }
    }
    if (!isPossibleNumber(v)) {
      return "Not a possible number"
    }
  }
}

export const CertificationsInput = () => {
  const classes = useStyles()
  return (
    <ArrayInput source="certifications" label="">
      <SimpleFormIterator>
        <TextInput
          source="certificate_name"
          label="Name"
          fullWidth
          validate={required()}
        />
        <TextInput source="institute_name" label="Institution" />
        <TextInput source="issued_id" label="Certificate ID" />
        <TextInput source="jurisdiction" label="Jurisdiction" />
        <DateInput
          source="issued_date"
          label="Issue Date"
          formClassName={classes.inline}
        />
        <DateInput
          source="expiration_date"
          label="Expiration Date"
          formClassName={classes.inline}
        />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export const WorkExperiencesForm = (props) => {
  const classes = useStyles()
  return (
    <SimpleFormIterator {...props}>
      <TextInput
        source="company_name"
        label="Company Name"
        fullWidth
        validate={[required(), maxLength(400)]}
      />
      <TextInput
        source="project_name"
        label="Project Name"
        fullWidth
        validate={[required(), maxLength(400)]}
      />
      <TextInput
        source="job_title"
        label="Job Title"
        validate={[required(), maxLength(75)]}
        fullWidth
      />
      <TextInput
        source="job_description"
        label="Job Description"
        fullWidth
        multiline
        validate={maxLength(2400)}
      />
      <DateInput
        source="start_date"
        formClassName={classes.inline}
        label="Start Date"
        validate={required()}
      />
      <DateInput
        source="end_date"
        formClassName={classes.inline}
        label="End Date"
      />
    </SimpleFormIterator>
  )
}
